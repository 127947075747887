.component-modal-main {
    background-color: white;

    // when shown
    &:not(.mfp-hide){
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 700px;
        margin: 0 auto;
        position: relative;
    }





    .mfp-content {
        background-color: white;
    }

    .mfp-close {
        color: white;
        opacity: 0.7;

        top: -40px;
        right: -15px;
        font-size: 30px;

        @include md-and-up {
            font-size: 36px;
            right: -40px;

        }


        &:hover {
            opacity: 1;
        }
    }

    .modal-content {
        position: relative;



    }

    .modal-text {
        padding: 30px;
    }

    form {

    }

}
